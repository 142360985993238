import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter } from "../../../node_modules/@fortawesome/free-brands-svg-icons/index";

class Footer extends Component {
  render() {
    return (
      <footer className="stripBlack footer">
        <div className="container has-text-centered">
          <div className="columns">
            <div className="column">
              <a
                className="social-icon"
                href="https://www.instagram.com/thewolfisdeadgame/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                className="social-icon"
                href="https://twitter.com/wolfisdeadgame"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>
            <div className="column">
              <p>
                Wolf is Dead is a game from Fat Toad Games Ltd. Companies
                Number: 13395422
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
