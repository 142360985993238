import React, { Component } from "react";
import { Link, StaticQuery } from "gatsby";
import { ABOUT_PAGE, HOME_PAGE } from "src/routesConstants";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

class NavBar extends Component {
  toggleMenu = () => {
    this.setState((prevState) => ({
      toggleMenu: !prevState.toggleMenu,
    }));
  };

  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
    };
  }

  render() {
    const isMenuActive = this.state.toggleMenu ? "is-active" : "";
    const logoWide = getImage(this.props.data.logoWide);
    return (
      <section className="stripBlack">
        <nav
          className="navbar container is-black"
          role="navigation"
          aria-label="main navigation"
        >
          <div className={`navbar-brand  ${isMenuActive}`}>
            <Link to={HOME_PAGE} className="m-r-lg m-t-xs">
              <GatsbyImage image={logoWide} fadeIn={false} />
              {/* <StaticImage
                src="src/images/logo-horizontal-black.jpg.png"
                alt="A dinosaur"
              /> */}
            </Link>
            <a
              role="button"
              className="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
              onClick={this.toggleMenu}
              tabIndex={0}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div className={`navbar-menu  ${isMenuActive}`}>
            <Link className="navbar-item" to={ABOUT_PAGE}>
              About
            </Link>
          </div>
        </nav>
      </section>
    );
  }
}

export default ({}) => (
  <StaticQuery
    query={graphql`
      query {
        logoWide: file(relativePath: { eq: "logo-horizontal-black.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 200, aspectRatio: 3.56, quality: 100)
          }
        }
      }
    `}
    render={(data) => <NavBar data={data} />}
  />
);
