import React from "react";
import Footer from "src/components/Footer/Footer";
import NavBar from "src/components/NavBar/NavBar";

// Layout needs columns
import "normalize.css";
import "src/styles/main.scss";

const MainLayout = ({ children }) => (
  <React.Fragment>
    <NavBar />
    <div className="mobileSpacer">{children}</div>
    <Footer />
  </React.Fragment>
);

export default MainLayout;
